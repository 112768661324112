<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path
      d="M34.9446 0H34.94L23.1406 15.7111L26.2544 19.8613L34.9446 8.2912V21.7277H40.0486V0H34.9446Z"
      fill="#11A3EB"
    />
    <path
      d="M13.785 28.1609L5.10404 39.7218V26.2715H0V46.5134V47.9992H5.11322L16.9034 32.3065L13.785 28.1609Z"
      fill="#11A3EB"
    />
    <path
      d="M34.9441 26.2723V39.7226L24.892 26.3411L16.7154 15.4543L5.10863 0H5.10404H0V21.7277H5.10404V8.2912L16.908 24.0069L16.9034 24.0161L20.0172 28.1617L20.0264 28.1525L34.935 48H40.0482V46.5142V26.2723H34.9441Z"
      fill="#11A3EB"
    />
  </svg>
</template>
